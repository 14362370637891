@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  position: relative;
  overflow-x: hidden !important;

  font-family: "Rubik", sans-serif;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  width: 100%;
  position: relative;

  overflow-x: hidden;
}

html,
body {
  display: block;
  box-sizing: border-box;
  transition: all 0.5s;
  overflow-x: hidden;
}

body {
  width: 100%;
  background-color: #eeeded;
  color: #000000;
  display: flex;
  flex-direction: column;
}

.container {
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1920px) {
  html {
    font-size: 62.5%;
  }
}
@media (max-width: 1536px) {
  .grid-cols-4 {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 79em) {
  html {
    font-size: 58%;
  }
  .grid-cols-4 {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
  .grid-cols-3 {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 56em) {
  html {
    font-size: 50%;
  }
}

@media (max-width: 50em) {
  html {
    font-size: 40%;
  }
}

@media (max-width: 40em) {
  html {
    font-size: 26%;
  }
}
@media (max-width: 30em) {
  .grid-cols-3 {
    grid-template-columns: 1fr;
  }
}
